import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getChallengesListWithMocks } from '../data';

export default async function handleUserLogin(flowAPI: ControllerFlowAPI) {
  flowAPI.controllerConfig.wixCodeApi.user.onLogin(async (): Promise<void> => {
    flowAPI.controllerConfig.setProps({
      challengesListData: {
        ...(await getChallengesListWithMocks(flowAPI)),
      },
    });
  });
}
